<template>
    <noscript>
        <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-WL9RHQ94"
            height="0"
            width="0"
            style="display: none; visibility: hidden"
        >
        </iframe>
    </noscript>
    <CommonComponentsModalApplyTrial />
    <CommonComponentsModalUserRoleSelector />
    <header>
        <CommonComponentsHeader />
    </header>
    <main>
        <slot />
    </main>
</template>

<script setup lang="ts">
    import { onMounted } from 'vue';
    import { lazyloaderUpdate } from '../composables/useLazyload';

    onMounted(() => {
        lazyloaderUpdate();
    });
</script>

<style lang="less">
    main {
        width: 100%;
    }
</style>
